const formatDate = (date, formatString, options = {}) => {
  const dateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  if (!formatString) {
    const { locale = 'en-US' } = options;

    return new Intl.DateTimeFormat(locale, dateTimeFormatOptions).format(date);
  }

  const replacements = {
    yyyy: date.getFullYear(),
    MM: `0${date.getMonth()}`.slice(-2),
    dd: `0${date.getDate()}`.slice(-2),
    HH: `0${date.getHours()}`.slice(-2),
    mm: `0${date.getMinutes()}`.slice(-2),
    ss: `0${date.getSeconds()}`.slice(-2)
  };

  return formatString.replace(
    /yyyy|MM|dd|HH|mm|ss/g,
    (match) => replacements[match]
  );
};

module.exports = {
  formatDate
};
