import { isDate } from '../shared/utils/isDate';
import { isNumber } from '../shared/utils/isNumber';
import { formatDate } from '../shared/utils/formatDate';

export const format = (value, formatString, lng) => {
  if (isDate(value)) {
    return formatDate(value, formatString || undefined, { locale: lng });
  }

  if (isNumber(value) && formatString === 'digits') {
    return value.toLocaleString(lng);
  }

  return value;
};
