import { Router } from 'next/router';
import { useEffect } from 'react';
import { pageView } from '../lib/pageView';

export const useTrackRouteChange = ({ tagID }) => {
  useEffect(() => {
    const handleRouteChange = (url) => {
      pageView({ path: url.toString() }, tagID);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tagID]);
};
