import React, { Suspense } from 'react';
import Link from 'next/link';
import { appWithTranslation } from 'next-i18next';
import { CssBaseline } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@ai-next/ui/src/system/theme';
import dynamic from 'next/dynamic';
import { format } from '../i18n/formatClient';
import nextI18nConfig from '../next-i18next.config';
import { YandexMetricaProvider } from '../shared/utils/YandexMetricaProvider/YandexMetricaProvider';
import { createEmotionCache } from '../shared/utils/create-emotion-cache';
import { useOnScroll } from '../shared/utils/useOnScroll';
import { GoogleAnalyticsProvider } from '../shared/utils/GoogleAnalytics/GoogleAnalytics';
import { event } from '../shared/utils/GoogleAnalytics/lib/event';
import { useRegisterSW } from '../shared/utils/useRegisterSW';

const LazyCookiePanel = dynamic(
  () => import('../shared/ui/CookiePanel').then((m) => m.CookiePanel),
  { ssr: false }
);

Link.defaultProps = { prefetch: false };

const clientSideEmotionCache = createEmotionCache();

const isDevelopment = process.env.NODE_ENV === 'development';

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}) => {
  const { error, ...props } = pageProps;

  const theme = createTheme();

  const haveScroll = useOnScroll();

  useRegisterSW({ needActivate: haveScroll });

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleAnalyticsProvider
          tagID={process.env.NEXT_PUBLIC_GOOGLE_TAG_ID}
          useAfterScrollAndTimeout
          nonce="Google-Analytics"
        />
        <YandexMetricaProvider
          disabled={isDevelopment}
          tagID={process.env.NEXT_PUBLIC_YANDEX_TAG_ID}
          useAfterScrollAndTimeout
          initParameters={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          }}
        >
          <Component {...props} />
        </YandexMetricaProvider>
        <Suspense fallback={null}>{haveScroll && <LazyCookiePanel />}</Suspense>
      </ThemeProvider>
    </CacheProvider>
  );
};

export function reportWebVitals({ id, name, label, value }) {
  event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true
  });
}

export default appWithTranslation(App, {
  ...nextI18nConfig,
  interpolation: { format, escapeValue: false }
});
