export const ym = (tagID, ...parameters) => {
  // eslint-disable-next-line prefer-destructuring, no-shadow
  const ym = window.ym;

  if (!ym || !tagID) {
    return;
  }

  ym(tagID, ...parameters);
};
