import Script from 'next/script';
import React, { createContext, useMemo } from 'react';
import { MetricaPixel } from './MetricaPixel';
import { useTrackRouteChange } from './hooks/useTrackRouteChange';

export const MetricaTagIDContext = createContext(null);

export const YandexMetricaProvider = ({
  disabled = false,
  children,
  tagID,
  strategy = 'afterInteractive',
  initParameters = {},
  shouldUseAlternativeCDN = false,
  useAfterScrollAndTimeout = false,
  timeout = 1000
}) => {
  const id = useMemo(() => tagID, [tagID]);

  useTrackRouteChange({ tagID: id });

  if (disabled) {
    return children;
  }

  if (!id) {
    return children;
  }

  const scriptSrc = shouldUseAlternativeCDN
    ? 'https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js'
    : 'https://mc.yandex.ru/metrika/tag.js';

  const metricaCode = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();
    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "${scriptSrc}", "ym");
    ym(${id}, "init", ${JSON.stringify(initParameters)});
    `;

  let scriptData = metricaCode;

  if (useAfterScrollAndTimeout) {
    scriptData = `
    var isFirstScroll = true;

    window.addEventListener('scroll', () => {
        if (isFirstScroll) {
            isFirstScroll = false;

            setTimeout(() => {
                ${metricaCode}
            }, ${timeout});
        }
    });
    `;
  }

  return (
    <>
      <Script id="yandex-metrica" strategy={strategy}>
        {scriptData}
      </Script>
      <noscript id="yandex-metrica-pixel">
        <MetricaPixel tagID={id} />
      </noscript>
      <MetricaTagIDContext.Provider value={id}>
        {children}
      </MetricaTagIDContext.Provider>
    </>
  );
};
