/* eslint-disable react/forbid-elements */
import React from 'react';

export const MetricaPixel = ({ tagID }) => (
  <img
    height="1"
    width="1"
    style={{ display: 'none' }}
    src={`https://mc.yandex.ru/watch/${tagID}`}
    alt="Yandex.Metrika"
    title="Yandex.Metrika"
  />
);
