import { useEffect } from 'react';

export const useRegisterSW = ({ needActivate }) => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      window.workbox !== undefined &&
      needActivate
    ) {
      const wb = window.workbox;

      wb.register();
    }
  }, [needActivate]);
};
