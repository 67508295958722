const {
  ENUM_NAMESPACES,
  LOCALES_ENUM,
  LANG_EN,
  LANG_RU,
  FALLBACK_LANGUAGE,
  NAMESPACES
} = require('./constants/locales');
const { format } = require('./i18n/formatServer');

const localeDisplayNames = new Map([
  [LANG_RU, { full: 'Russian', short: 'ru', flag: '🇷🇺' }],
  [LANG_EN, { full: 'English', short: 'en', flag: '🇺🇸' }]
]);

module.exports = {
  i18n: {
    locales: LOCALES_ENUM,
    defaultLocale: LANG_RU,
    localeDisplayNames,
    allNamespaces: ENUM_NAMESPACES
  },
  interpolation: {
    format,
    escapeValue: false // react already safes from xss
  },
  serializeConfig: false,
  defaultNS: NAMESPACES.common,
  fallbackNS: ENUM_NAMESPACES,
  fallbackLng: FALLBACK_LANGUAGE,
  nonExplicitSupportedLngs: true,
  nonExplicitWhiteList: false,
  lowerCaseLng: true,
  cleanCode: true,
  debug: process.env.NODE_ENV === 'development',
  react: {
    useSuspense: false
  }
};
