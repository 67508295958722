export function event(
  action,
  { category, label, value, nonInteraction, userId, ...otherOptions } = {}
) {
  if (!window.gtag) {
    return;
  }

  const eventOptions = { ...otherOptions };

  if (category !== undefined) {
    eventOptions.event_category = category;
  }

  if (label !== undefined) {
    eventOptions.event_label = label;
  }

  if (value !== undefined) {
    eventOptions.value = value;
  }

  if (nonInteraction !== undefined) {
    eventOptions.non_interaction = nonInteraction;
  }

  if (userId !== undefined) {
    eventOptions.user_id = userId;
  }

  window.gtag('event', action, eventOptions);
}
