const fontWeight = {
  light: 400,
  regular: 500,
  medium: 700
};

const fontHeader = {
  fontFamily: "'Roboto Condensed', sans-serif",
  textTransform: 'uppercase',
  fontWeight: fontWeight.medium,
  lineHeight: 'normal',
  wordBreak: 'break-word'
};

const fontSubtitle = {
  fontWeight: fontWeight.regular,
  lineHeight: 'normal'
};

const fontBody = {
  fontWeight: fontWeight.light,
  lineHeight: 'normal'
};

export const createTypography = () => ({
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  body1: { fontSize: 16, ...fontBody },
  body2: { fontSize: 14, ...fontBody },
  button: { fontWeight: fontWeight.regular },
  caption: {
    fontSize: '0.75rem',
    fontWeight: fontWeight.regular,
    lineHeight: 'normal'
  },
  subtitle1: { fontSize: 18, ...fontSubtitle },
  subtitle2: { fontSize: 16, ...fontSubtitle },
  h1: { fontSize: 60, ...fontHeader },
  h2: { fontSize: 48, ...fontHeader },
  h3: { fontSize: 42, ...fontHeader },
  h4: { fontSize: 34, ...fontHeader },
  h5: { fontSize: 20, ...fontHeader },
  h6: { fontSize: 18, ...fontHeader }
});
