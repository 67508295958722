import {
  createTheme,
  filledInputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paperClasses,
  tableCellClasses,
  tabClasses
} from '@mui/material';

const muiTheme = createTheme();

export function createComponents(config) {
  const { palette } = config;

  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '2px 16px'
        },
        sizeMedium: {
          padding: '4px 20px'
        },
        sizeLarge: {
          padding: '8px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          padding: '32px 24px 16px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          "& ::-webkit-scrollbar": {
            width: '16px'
          },
          "& ::-webkit-scrollbar-thumb": {
            cursor: 'default',
            background: 'linear-gradient(120deg, rgb(34, 43, 69) 0%, rgb(26, 33, 56) 100%)',
            borderRadius: '25px',
            border: '3px solid rgb(39 49 79)' 
          },
          "& ::-webkit-scrollbar-track": {
            backgroundColor: '#45506d',
            borderRadius: '0px'
          }
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        },
        '#nprogress': {
          pointerEvents: 'none'
        },
        '#nprogress .bar': {
          backgroundColor: palette.primary.main,
          height: 3,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 1,
          overflow: 'hidden',
          borderColor: palette.primary.main,
          transition: muiTheme.transitions.create([
            'border-color',
            'box-shadow'
          ]),
          '&:hover': {
            borderColor: palette.primary.main,
          },
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: 'transparent'
          },
          [`&.${filledInputClasses.focused}`]: {
            borderColor: palette.primary.main,
            boxShadow: `${palette.primary.main} 0 0 0 2px`
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: palette.error.main,
            boxShadow: `none`
          }
        },
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          color: 'white'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          },
          [`&.${tabClasses.disabled}`]: {
            color: '#ffffff'
          },
          color: 'rgba(229, 231, 235, 0.7)'
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              color: palette.primary.main,
              borderColor: palette.primary.main,
            }
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              color: palette.primary.main,
              borderColor: palette.primary.main,
            }
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.error.main,
              boxShadow: 'none'
            }
          }
        },
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '24px',
          color: 'white'
        },
        notchedOutline: {
          color: palette.primary.main,
          borderColor: palette.primary.main,
          transition: muiTheme.transitions.create([
            'border-color',
            'box-shadow'
          ])
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
          padding: '15px 16px',
          color: 'rgb(174, 176, 180)'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            backgroundColor: '#222b45',
            color: 'rgb(238, 238, 239)',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          },
          [`& .${tableCellClasses.paddingCheckbox}`]: {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiChip: {
      defaultProps: { size: 'small', type: 'INFO' },
      variants: [
        { props: { size: 'small' }, style: { padding: '0 6px' } },
        { props: { size: 'medium' }, style: { padding: '0 12px' } },
        {
          props: { type: 'ERROR' },
          style: {
            color: palette.orange.dark,
            backgroundColor: palette.orange.light
          }
        },
        {
          props: { type: 'WARNING' },
          style: {
            color: palette.warning.dark,
            backgroundColor: palette.warning.light
          }
        },
        {
          props: { type: 'SUCCESS' },
          style: {
            color: palette.success.dark,
            backgroundColor: palette.success.light
          }
        },
        {
          props: { type: 'INFO' },
          style: {
            color: palette.info.dark,
            backgroundColor: palette.info.light
          }
        }
      ]
    }
  };
}
