const { isDate } = require('../shared/utils/isDate');
const { isNumber } = require('../shared/utils/isNumber');
const { formatDate } = require('../shared/utils/formatDate');

const format = (value, formatString, lng) => {
  if (isDate(value)) {
    return formatDate(value, formatString || undefined, { locale: lng });
  }

  if (isNumber(value) && formatString === 'digits') {
    return value.toLocaleString(lng);
  }

  return value;
};

module.exports = { format };
