import Script from 'next/script';
import React, { useMemo } from 'react';
import { useTrackRouteChange } from './hooks/useTrackRouteChange';

export const GoogleAnalyticsProvider = ({
  children,
  tagID,
  strategy = 'afterInteractive',
  useAfterScrollAndTimeout = false,
  timeout = 1000,
  nonce = ''
}) => {
  const id = useMemo(() => tagID, [tagID]);

  useTrackRouteChange({ tagID: id });

  if (!id) {
    return children;
  }

  const metricaCode = `
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.nonce = '${nonce}';
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', '${tagID}');
  };
  script.src = 'https://www.googletagmanager.com/gtag/js?id=${tagID}';
  script.defer = true;
  document.getElementsByTagName('body')[0].appendChild(script);
    `;

  let scriptData = metricaCode;

  if (useAfterScrollAndTimeout) {
    scriptData = `
    var fired = false;

    window.addEventListener('scroll', () => {
        if (!fired) {
            fired = true;

            setTimeout(() => {
                ${metricaCode}
            }, ${timeout});
        }
    });
    `;
  }

  return (
    <Script id="GTM" strategy={strategy} nonce={nonce}>
      {scriptData}
    </Script>
  );
};
