const IMAGE_SIZES = {
  xs: '-m', // mobile
  lg: '-t', // tablet
  xxl: '-d' // desktop
};

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1440
};

module.exports = {
  IMAGE_SIZES,
  BREAKPOINTS
};
