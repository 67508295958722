import { Router } from 'next/router';
import { useEffect } from 'react';
import { ym } from '../lib/ym';

export const useTrackRouteChange = ({ tagID }) => {
  useEffect(() => {
    const handleRouteChange = (url) => {
      ym(tagID, 'hit', url.toString());
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tagID]);
};
